import { Link } from "react-router-dom"

const footerNavigation = {

  footerContent: [
    {
      sectionTitle: 'Company',
      links: [
        { name: 'About Us', href: 'https://www.beatsora.com/about' },
        { name: 'Newsletter', href: 'https://mailchi.mp/beatsora/beatsora-audience-sign-up' },
        { name: 'Blog', href: 'https://www.beatsora.world/blog' },
        { name: 'Submissions', href: 'https://forms.monday.com/forms/c944bd1791bcf71977bf384e1846271b?r=euc1' },
        { name: 'Partners', href: 'https://www.beatsora.com/partners' },
        { name: 'Artist Hub', href: 'https://www.beatsora.com/hub' },
        { name: 'Beatsora Education', href: 'https://www.beatsora.com/education' },
        { name: 'Merch', href: 'https://www.beatsora.com/comingsoon' },
      ],
    },
    {
      sectionTitle: 'Support',
      links: [
        { name: 'Support Request', href: 'https://forms.monday.com/forms/41cf291ffe43a3917515e8ab44c1b0b3?r=euc1' },
        { name: 'Help Centre', href: 'https://www.beatsora.com/helpcentre' },
        { name: 'Privacy Policy', href: 'https://app.termly.io/document/privacy-policy/ec31a046-2cf2-4fc4-8be4-6622f0035ae8' },
        { name: 'Terms of Service', href: 'https://app.termly.io/document/terms-of-service/e2595200-267e-4272-9008-c22ffc0ab4a3' },
        { name: 'Disclaimer', href: 'https://app.termly.io/document/disclaimer/0af03370-049a-46e1-8b23-fcc1803f53f8' },
        { name: 'General Inquiries', href: 'https://forms.monday.com/forms/0fe644bf37eb3ac0633c178c1b1e05b7?r=euc1' },
      ],
    },
  ],
  social: [
    {
      name: 'Youtube',
      href: 'https://www.youtube.com/@Beatsora',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 -10 470 470" {...props}>
          <path
            fillRule="evenodd"
            d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
              c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
              C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
              c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/beatsora_',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/Beatsora_',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="-30 -30 340 340" {...props}>
          <path d="m 236 0 h 46 l -101 115 l 118 156 h -92.6 l -72.5 -94.8 l -83 94.8 h -46 l 107 -123 l -113 -148 h 94.9 l 65.5 86.6 Z m -16.1 244 h 25.5 l -165 -218 h -27.4 Z" />
        </svg>
      ),
    },
    {
      name: 'Tiktok',
      href: 'https://www.tiktok.com/@beatsora',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 34 34" {...props}>
          <path d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z" />
        </svg>
      ),
    },
  ],
}

{/**

const footerNavigation = {

    footerContent: [
        {
            sectionTitle: 'Company',
            links: [
                { name: 'About Us', href: '/about', type: 'route' },
                { name: 'Newsletter', href: 'https://mailchi.mp/8036b1e3934b/beatsora-mailing-list' },
                { name: 'Submissions', href: 'https://forms.monday.com/forms/c944bd1791bcf71977bf384e1846271b?r=euc1' },
                { name: 'Partners', href: '/partners', type: 'route' },
                { name: 'Artist Hub', href: '/comingsoon', type: 'route' },
                { name: 'Merch', href: '/comingsoon', type: 'route' },
              ],
        },
        {
            sectionTitle: 'Support',
            links: [
                { name: 'Support Request', href: 'https://forms.monday.com/forms/41cf291ffe43a3917515e8ab44c1b0b3?r=euc1' },
                { name: 'Help Centre', href: '/helpcentre', type: 'route' },
                { name: 'Privacy Policy', href: 'https://app.termly.io/document/privacy-policy/ec31a046-2cf2-4fc4-8be4-6622f0035ae8' },
                { name: 'Terms of Service', href: 'https://app.termly.io/document/terms-of-service/e2595200-267e-4272-9008-c22ffc0ab4a3' },
                { name: 'Disclaimer', href: 'https://app.termly.io/document/disclaimer/0af03370-049a-46e1-8b23-fcc1803f53f8' },
                { name: 'General Inquiries', href: 'https://forms.monday.com/forms/00aeb63b4bda7907d0abfc9840560835?r=euc1' },
              ],
        },
    ],
    social: [
      {
        name: 'Youtube',
        href: 'https://www.youtube.com/@Beatsora',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 -10 470 470" {...props}>
            <path
              fillRule="evenodd"
              d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
              c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
              C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
              c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/profile.php?id=100091082352694',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/beatsora_',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/Beatsora_',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="-30 -30 340 340" {...props}>
            <path d="m 236 0 h 46 l -101 115 l 118 156 h -92.6 l -72.5 -94.8 l -83 94.8 h -46 l 107 -123 l -113 -148 h 94.9 l 65.5 86.6 Z m -16.1 244 h 25.5 l -165 -218 h -27.4 Z" />
          </svg>
        ),
      },
      {
        name: 'Tiktok',
        href: 'https://www.tiktok.com/@beatsora',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 34 34" {...props}>
            <path d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z" />
          </svg>
        ),
      },
      {
        name: 'Discord',
        href: 'https://discord.com/invite/7wgNCgu3',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 -50 300 300" {...props}>
            <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z" />
          </svg>
        ),
      },
    ],
  }

*/}

export function Footer({ }) {

  return (
    <>
      {/* Footer 4-column with newsletter and localization dark */}
      <footer className="bg-stone-900">
        <div className="mx-auto max-w-4xl py-12 px-32 lg:py-16 lg:px-8">
          <div className="pt-32" />
          <h2 className="sr-only">Footer</h2>
          <div className="pb-8 xl:grid xl:grid-cols-1 xl:gap-8">
            <div className="grid grid-cols-1 gap-8 xl:col-span-1">
              <div className="grid grid-cols-2 md:gap-8">

                {footerNavigation.footerContent.map((section) => (
                  <div className="mt-12 md:mt-0">
                    <h3 className="text-base font-medium text-white text-center md:text-left">{section.sectionTitle}</h3>
                    <ul role="list" className="mt-8 space-y-4">
                      <div className="md:grid md:grid-cols-2 md:gap-8 items-center">
                        {section.links.map((link) => {
                          return <li key={link.name}>
                            <a href={link.href} target="_blank" className="text-base text-gray-300 hover:text-danger mx-auto md:mx-0">
                              <div className="text-center md:text-left">
                                {link.name}
                              </div>
                            </a>
                            {/** 

                              { !link?.type && <a href={link.href} target="_blank" className="text-base text-gray-300 hover:text-danger mx-auto md:mx-0">
                                <div className="text-center md:text-left">
                                  {link.name}
                                </div>
                              </a> }
                              { link?.type && <Link to={link.href} className="text-base text-gray-300 hover:text-danger mx-auto md:mx-0">
                                <div className="text-center md:text-left">
                                  {link.name}
                                </div>
                                </Link> }
                                */}
                          </li>
                        })}
                      </div>
                    </ul>
                  </div>
                ))}
              </div>

            </div>

          </div>
          {/**
        <div className="space-y-4 border-t border-gray-700 pt-8 lg:flex lg:items-center lg:justify-between lg:space-y-0 xl:mt-0">
            <div className="space-y-2">
                <h3 className="text-base font-medium text-white">Subscribe to our newsletter</h3>
                <p className="text-base text-gray-300">
                The latest news, articles, and resources, sent to your inbox weekly.
                </p>
            </div>
            <form className="sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                Email address
                </label>
                <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="w-full min-w-0 rounded-md border border-transparent bg-white py-2 px-4 placeholder-gray-500 focus:border-white focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 sm:max-w-xs"
                placeholder="Enter your email"
                />
                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-danger py-2 px-4 text-base font-medium text-white hover:bg-dangerdarker focus:outline-none focus:ring-2 focus:ring-dangerlighter focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                    Subscribe
                </button>
                </div>
            </form>
        </div>
         */}
          <div className="mt-8 border-t border-gray-700 pt-8 md:flex items-center justify-center md:justify-between mx-auto">
            <div className="hidden md:block">
              <div className="inline-flex space-x-20 md:order-2 pt-4 mx-auto md:mx-0 items-center">
                {footerNavigation.social.map((item) => (
                  <a key={item.name} target="_blank" href={item.href} className="text-gray-400 hover:text-danger mx-auto">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-30 w-30 mx-auto" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="block md:hidden">
              <div className="grid grid-cols-4 space-x-20 pt-4 mx-auto items-center max-w-xs">
                {footerNavigation.social.map((item) => (
                  <a key={item.name} target="_blank" href={item.href} className="text-gray-400 hover:text-danger mx-auto">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-30 w-30 mx-auto" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-2 text-center md:text-left">
              &copy; 2024 Beatsora Ltd. All rights reserved.
            </p>
          </div>
        </div>
        <div className="pt-256 md:pt-288 block lg:hidden" />
      </footer>
    </>

  )
}
