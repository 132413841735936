import React, { useState } from 'react';
import { Track } from '@app/landing-page/tracks/track';
import { Link } from 'react-router-dom';
import { TagButton } from '@app/landing-page/com/tags/tag-button'; // Make sure to update the path
import { Tooltip } from 'flowbite-react';

interface TrackTagsColumnProps {
    tags: Track['tags'];
}


export function TrackTagsColumn({ tags }: TrackTagsColumnProps) {
    if (!tags || tags.length === 0) return null;

    const visibleTags = tags.slice(0, 1);
    const hiddenTags = tags.slice(1);

    return (
        <>
            
                <div className="flex space-x-2 overflow-hidden">
                    {visibleTags.map(tag => (
                        <Tooltip content={
                            <div className="flex flex-wrap p-8 max-w-[260px]">
                                    {hiddenTags.map(hitag => (
                                        <div className='pt-3 pr-6 md:pt-0'>
                                            <TagButton key={hitag.id} tagname={hitag.name} url={`/tag/${hitag.name}`} />
                                        </div>
                                    ))}
                            </div>
                        }>
                        <div className='pr-4'>
                            <TagButton key={tag.id} tagname={tag.name} url={`/tag/${tag.name}`} />
                        </div>
                        </Tooltip>
                    ))}
                </div>

    </>
    );
}