import React, { useEffect } from 'react';

interface ScrollingTextTickerProps {
    text: React.ReactNode;
}
  

  const ScrollingTextTicker: React.FC<ScrollingTextTickerProps> = ({ text }) => {
  useEffect(() => {
    // Add the animation class after the component has mounted
    const tickerElement = document.getElementById('ticker');
    if (tickerElement) {
      tickerElement.classList.add('animate-scroll');
    }
  }, []);

  const tickerStyle: React.CSSProperties = {
    overflow: 'hidden',
    background: 'transparent',
    padding: '8px',
  };

  const textContainerStyle: React.CSSProperties = {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    animation: 'scroll 12s linear infinite 2s',
  };

  return (
    <div style={tickerStyle}>
      <div id="ticker" style={textContainerStyle}>
        {text}
      </div>
    </div>
  );
};

export default ScrollingTextTicker;
