import React, { useRef } from 'react';
import { YouTubePlayer } from "@app/landing-page/social-media-embeds";
import { LicenseInfoSection } from "@common/billing/pricing-table/license-info";
import { ContactSection } from "@common/billing/pricing-table/pricing-layout-page";
import { LayoutPricing } from "@common/billing/pricing-table/pricing-layout-table";
import { CheckIcon } from '@heroicons/react/20/solid';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { NavbarNavigation } from "@app/web-player/layout/navbar-navigation";
import { Footer } from "@app/web-player/layout/footer";
import { useProducts } from '@common/billing/pricing-table/use-products';
import { useAuth } from '@common/auth/use-auth';
import { HomePageCompanies } from '@app/landing-page/landing-page';
import {
    findBestPrice,
    UpsellBillingCycle,
} from '@common/billing/pricing-table/find-best-price';

import pricing from './pricing-page-images/pricing.png'


export function FreeTrialIntroSection({ onPlansClick } : { onPlansClick : any }) {

    return (
        <div className="bg-gradient-to-b from-danger/70 to-black/60">
            <div className='mx-auto max-w-2xl lg:max-w-[1600px]'>
                <div className='pt-0 lg:pt-48'/>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-24 gap-y-24 pb-6 xxl:pb-36 pt-48 md:pt-48 px-24">
                    <div className='xxl:pr-128'>
                        <div className='text-4xl md:text-6xl font-semibold md:font-bold text-center lg:text-left'>
                            Get beats every month with a Beatsora subscription!
                        </div>
                        <div className='text-2xl md:text-3xl pt-20 font-semibold text-center lg:text-left'>
                        Get 25% off your first two months using <b>code SAVE25!</b>
                        </div>
                        {/**
                        <div className='text-xl md:text-2xl pt-12 pb-12 font-bold text-center lg:text-left'>
                            First beat is on us!
                        </div>
                         */}
                        <div className="pt-12 flex flex-col xss:flex-row items-center gap-x-6 mx-auto lg:mx-0  max-w-md lg:max-w-none">
                            <button
                                onClick={onPlansClick}
                                className="rounded-md max-w-[150px] xxs:max-w-none bg-danger px-10 py-6 text-lg font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mb-4 xss:mb-0 mx-auto lg:mx-0"
                            >
                                View all plans
                            </button>
                            {/**
                            <div className='block pt-12 xss:pt-0 xss:hidden'/>
                            <button
                                onClick={onPlansClick}
                                className="rounded-md max-w-[150px] xxs:max-w-none border-2 border-danger px-10 py-6 text-lg font-semibold text-white shadow-sm hover:bg-white/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mx-auto lg:mx-0"
                            >
                                Try free for 7 days
                            </button>
                             */}
                        </div>

                        <div className='pt-12 text-xs text-white text-center lg:text-left pb-12 lg:pb-0'>
                        Offer available on first subscription only. <a target="_blank" className="text-danger font-semibold" href='https://app.termly.io/policy-viewer/policy.html?policyUUID=e2595200-267e-4272-9008-c22ffc0ab4a3'>Terms apply</a>.
                        </div>
                    </div>
                    <div className="hidden lg:block">
                        <img
                            alt=""
                            src={pricing}
                            className="w-full h-auto object-cover object-center "
                            />
                    </div>                

                </div>
            </div>
        </div>
    )
}

const subscriptionFeatures = [
    '2 beats per month',
    'Free AI Mastering',
    'Free Track Feedback',
    'Exclusive Partnership Discounts',
    'Email support'
]

export function SubscriptionFeatures() {
    return (
        <>
            <div className="pt-8 sm:pt-20 max-w-xl mx-auto">
                <div className="flex flex-col items-center">
                    <div className="text-3xl pt-24 pb-12 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">
                        What is included in a Beatsora subscription?
                    </div>

                    <ul
                        role="list"
                        className="text-white text-md sm:text-lg leading-6 mx-auto pb-36"
                    >
                        {subscriptionFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-4 py-2 items-center">
                                <CheckIcon
                                    className="text-danger h-18 w-15 flex-none"
                                    aria-hidden="true"
                                />
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}
export function FreeTrialPageLayout() {
    const layoutPricingRef = useRef(null);

    const handlePlansClick = () => {
        layoutPricingRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Navbar
                color="transparent"
                darkModeColor="transparent"
                className="flex-shrink-0"
                menuPosition="landing-page-navbar"
                primaryButtonColor="white"
            >
                <NavbarNavigation page='pricing' />
            </Navbar>
            <FreeTrialIntroSection onPlansClick={handlePlansClick} />
            <div className="bg-black/60">
                <HomePageCompanies bgColour="bg-black/0"/>
                <SubscriptionFeatures />
                <div ref={layoutPricingRef}>
                    <LayoutPricing />
                </div>
                <LicenseInfoSection />
                <ContactSection />
                <Footer />
            </div>
        </>
    )
}
