import { useIsMobileMediaQuery } from '@common/utils/hooks/is-mobile-media-query';
import clsx from 'clsx';
import { useCuedTrack } from '@app/landing-page/player-controls/use-cued-track';
import { ReactNode, useContext } from 'react';
import {Waveform} from '@app/landing-page/tracks/waveform/waveform';
import {Track} from '@app/landing-page/tracks/track';
import {
  CommentBarContext,
} from '@app/landing-page/tracks/waveform/comment-bar-context';
import {CommentBarNewCommentForm} from '@app/landing-page/tracks/waveform/comment-bar-new-comment-form';
import React, {Fragment, memo} from 'react';
import {AnimatePresence} from 'framer-motion';
import {useInfiniteData} from '@common/ui/infinite-scroll/use-infinite-data';
import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {IllustratedMessage} from '@common/ui/images/illustrated-message';
import {AudiotrackIcon} from '@common/icons/material/Audiotrack';
import {Trans} from '@common/i18n/trans';
import { useState} from 'react';
import {Tabs} from '@common/ui/tabs/tabs';
import {TabPanel, TabPanels} from '@common/ui/tabs/tab-panels';
import {TrackList} from '@app/landing-page/tracks/track-list/track-list';
import {usePaginatedChannelContent} from '@app/web-player/channels/requests/use-paginated-channel-content';
import { Channel } from '@common/channels/channel';

const tagTabNames = {
  tracks: 0,
  albums: 1,
};

interface Props {
  className?: string;
}

export function PlaybackControls({className}: Props){

  return (
    <div className={className}>
      {/* Changes by Parth */}
      <PlaybackButtonsNew1 />
    </div>
  );
}

function PlaybackButtonsNew1() {
  const isMobile = useIsMobileMediaQuery();
  const track = useCuedTrack();
  const tagName = 'erikah-badu';
  const tabName = 'erikah-badu';
  const [selectedTab, setSelectedTab] = useState(
    tagTabNames[tabName as keyof typeof tagTabNames] || 0
  );

  return (
      <>
        <div
          className={clsx(
            '',
            isMobile && 'mb-20'
          )}
        >
          <Fragment>
            <Tabs selectedTab={selectedTab} onTabChange={setSelectedTab}>
              <TabPanels className="pt-24">
                <TabPanel>
                  <TracksPanel tagName={tagName!} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Fragment>
        </div>
        
    </>
  );

}

interface WaveformWithCommentsProps {
  track: Track;
  queue?: Track[];
}
export function WaveformWithComments({
  track,
  queue,
}: WaveformWithCommentsProps) {
  const {markerIsVisible} = useContext(CommentBarContext);
  return (
    <Fragment>
      <Waveform track={track} queue={queue} />
      <AnimatePresence mode="wait">
        {markerIsVisible && (
          <CommentBarNewCommentForm
            className="mt-28 mb-8"
            commentable={track}
          />
        )}
      </AnimatePresence>
    </Fragment>
  );
}

interface TracksPanelProps {
  tagName: string;
}

function TracksPanel({tagName}: TracksPanelProps) {

  const channel: Channel<Track> = {
    id: 10,
    name: "Landing Page Channel",
    slug: "sample-channel",
    model_type: "channel",
    internal: false,
    public: true,
    type: '',
    config: {
      contentModel: '',
      contentType: 'listAll',
      contentOrder: 'channelables.order:asc',
      layout: '',
      nestedLayout: ''
    }
  };

  const query = usePaginatedChannelContent<Track>(channel)

  {/**
  const query = useInfiniteData<Track>({
    queryKey: ['tracks', 'tags', tagName],
    endpoint: `tags/${tagName}/tracks`,
  });
   */}

  if (query.isInitialLoading) {
    return <FullPageLoader className="min-h-100" />;
  }

  if (!query.items.length) {
    return (
      <IllustratedMessage
        imageHeight="h-auto"
        imageMargin="mb-14"
        image={<AudiotrackIcon size="lg" className="text-muted" />}
        title={<Trans message="No tracks yet" />}
        description={
          <Trans message="This tag is not attached to any tracks yet, check back later." />
        }
      />
    );
  }

  return <TrackList query={query} />;
}