import { Settings } from './settings';
import { useBootstrapData } from '@common/core/bootstrap-data/bootstrap-data-context';

export function useSettings(): Settings | null {
  const bootstrapData = useBootstrapData();
  if (!bootstrapData || !bootstrapData.data) {
    return null; // Return null if bootstrapData or data is null
  }
  return bootstrapData.data.settings;
}
