import { Link } from 'react-router-dom';

import { urlChecker } from './url-check-if-includes';

const navbarLinks = [
    {
      id: 1,
      title: "Explore Beats",
      link: "/explore",
      isPrimary: true,
    },
    {
      id: 2,
      title: "Artist Hub",
      link: "/hub",
      isPrimary: false,
    },
    {
    id: 3,
    title: "Education",
    link: "/education",
    isPrimary: false,
    },
    {
      id: 4,
      title: "Pricing",
      link: "/pricing",
      isPrimary: false,
    },
  ]
  
  

export function NavbarNavigation({page} : {page : string}) {
    const bannerBannedSlugs = ["/hub", "/pricing", "/education"];
    const highlightPrimary = !urlChecker(bannerBannedSlugs);
  
    return (
      <div className="ml-8 mr-18 space-x-24 h-full hidden xl:block">
        {navbarLinks.map((link) => {
          const isHighlighted = ((link.isPrimary && highlightPrimary) || (!link.isPrimary && urlChecker([link.link]))) && page !== 'landing';
          const borderClass = isHighlighted ? "border-danger text-white" : "border-transparent hover:border-gray-300";
  
          return (
            <Link
              key={link.link}
              to={link.link}
              className={`inline-flex h-full items-center border-b-4 text-md font-semibold hover:text-white ${borderClass}`}>
                <div className='pt-4'>
                  {link.title}
                </div>
            </Link>
          );
        })}    
        </div>
    );
  }
  