import React, { useState } from 'react';
import { ContextMenuButton } from '@app/landing-page/context-dialog/context-dialog-layout';
import { Trans } from '@common/i18n/trans';
import { Track } from '@app/landing-page/tracks/track';
import { Tooltip } from 'flowbite-react';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context'; // Reintroduce useDialogContext
import { downloadFileFromUrl } from '@app/landing-page/com/uploads/utils/download-file-from-url';
import { DownloadAgreement1 } from '@app/landing-page/com/modals/download-confirmation-modal1';
import { DownloadAgreement } from '@app/landing-page/com/modals/download-confirmation-modal';


interface DownloadTrackMenuButtonProps {
    track: Track;
}

export function DownloadTrackMenuButton({ track }: DownloadTrackMenuButtonProps) {
    const { close: closeMenu } = useDialogContext();
    const [showAgreement, setShowAgreement] = useState(false);

    const handleDownloadAgreement = () => {
        setShowAgreement(true);
        closeMenu();
    };

    function PricingTooltipd() {
        return (
          <div className='p-10 text-md font-semibold text-white text-center mx-auto'>
            1 credit
          </div>
        )
      }

    return (
<div>
  <DownloadAgreement track={track} player={false} from="button" />

{/*

        <Tooltip content={<Trans message="1 credit" />} style="dark">
            <DownloadAgreement track={track} player={false} from="button" />
             <DownloadAgreement1 track={track} /> 
          </Tooltip>
*/}
        {/* // <Tooltip content={<Trans message="Download Track" />} style="dark">
        //     <ContextMenuButton onClick={handleDownloadAgreement}>
        //         <Trans message="Download Track" />
        //     </ContextMenuButton>  */}



           {/* {showAgreement && ( 
                <DownloadAgreementMenu
                    track={track}
                    onClose={() => setShowAgreement(false)}
                    isOpen={showAgreement}
                />
            ) } */}

</div>
        // </Tooltip>
    );
}