import React from 'react';
import { Track } from '@app/landing-page/tracks/track';
import { GenreLink } from '@app/landing-page/genres/genre-link';
import { Tooltip } from 'flowbite-react';


interface TrackGenresColumnProps {
    genres: Track['genres'];
}

export function TrackGenresColumn({ genres }: TrackGenresColumnProps) {
    if (!genres || genres.length === 0) return null;

    const visibleGenres = genres.slice(0, 1);
    const hiddenGenres = genres.slice(1);

    return (
        <div className="flex space-x-2">
            {visibleGenres.map(thisGenre => (
                
                <Tooltip content={
                    <div className="flex flex-wrap p-8 max-w-[260px]">
                            {hiddenGenres.map(thisGenre => (
                                <div className='p-4'>
                                    <GenreStyledLink key={thisGenre.id} genre={thisGenre}/>
                                </div>
                            ))}
                    </div>
                }>
                    <div className='pt-3 pr-6 md:pt-0'>
                        <GenreStyledLink key={thisGenre.id} genre={thisGenre} />
                    </div>
                </Tooltip>
            ))}
            {/**
            {hiddenGenres.length > 0 && (
                <Tooltip content={
                    <div className="flex flex-wrap p-8 max-w-[260px]">
                            {hiddenGenres.map(thisGenre => (
                                <div className='p-4'>
                                    <GenreStyledLink key={thisGenre.id} genre={thisGenre}/>
                                </div>
                            ))}
                    </div>
                }>
                    <span className="rounded-full bg-gray-500 px-6 py-2 text-xs text-white shadow-sm hover:bg-gray-600 cursor-pointer">
                        +{hiddenGenres.length}
                    </span>
                </Tooltip>
            )}
             */}
        </div>
    );


}

function GenreStyledLink({ genre }: { genre: any }) {
    return (
        <GenreLink genre={genre} className="flex-none rounded-full bg-gray-500 px-8 py-4 text-xs md:text-sm text-white shadow-sm hover:bg-dangerdarker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
            {genre.name}
        </GenreLink>
    );
}
