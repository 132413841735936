var searchKeywordArray = [
    'afrobeat',
    'melodic drill', ~
    'guitar trap', 
    'hip hop', 
    'dark trap', 
    'boom bap', 
    'chill', 
    'jazzhop', 
    'Kendrick Lamar', 
    'Stormzy', 
    'Tyler the Creator', 
    'Burna Boy', 
    'Future', 
    'Migos', 
    '21 Savage',
    'J Cole',
    'Jay Z',
    'Lil Wayne',
    'Future',
    'Nas',
    'Pusha T',
    '21 Savage',
    'Rick Ross',
    'Cardi B',
    'Nicki Manaj',
    'Juice WRLD',
    '50 Cent',
    'Ice Spice',
    'Chief Keef',
  ];


var searchKeywordArrayIndex = Math.floor(Math.random()*searchKeywordArray.length);
var searchKeyword = searchKeywordArray[searchKeywordArrayIndex];
var searchPlaceholder = 'Search "' + searchKeyword + '"';

export { searchPlaceholder };