

//className="flex-none rounded-full bg-gray-500 px-8 py-2 text-xs md:text-sm text-white shadow-sm hover:bg-dangerdarker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
export function TagButton( {tagname, url} : {tagname:any, url:any} ) {
    return (
        <a
        href={url}
        className="block flex-none rounded-full bg-gray-500 px-8 py-4 text-xs md:text-sm text-white shadow-sm hover:bg-dangerdarker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
            {tagname} <span aria-hidden="true"></span>
        </a>
    )
  }

