import { usePlayerStore } from '@app/landing-page/com/player/hooks/use-player-store';
import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';

// Extending the Props interface to include the new imageUrl prop
interface Props extends HTMLAttributes<HTMLDivElement> {
  hideDuringPlayback?: boolean;
  imageUrl?: string; // New prop for the image URL
}

export function PlayerPoster({
  className,
  hideDuringPlayback = true,
  imageUrl, // Include the new prop
  ...domProps
}: Props) {
  const posterUrl = usePlayerStore(s => s.posterUrl); // Existing posterUrl from the player store
  const shouldHidePoster = usePlayerStore(
    s =>
      hideDuringPlayback && s.playbackStarted && s.providerName !== 'htmlAudio'
  );

  const src = imageUrl || posterUrl; // Use imageUrl if provided, else fallback to posterUrl

  if (!src) return null;

  return (
    <div
      {...domProps}
      className={clsx(
        'transition-opacity pointer-events-none flex items-center justify-center bg-black w-full max-h-full',
        shouldHidePoster ? 'opacity-0' : 'opacity-100',
        className
      )}
    >
      <img
        loading="lazy"
        src={src}
        alt=""
        className="w-full max-h-full object-cover flex-shrink-0"
      />
    </div>
  );
}
