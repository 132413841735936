import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition, RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, XMarkIcon, ArrowDownCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { useAuth } from '@app/landing-page/com/auth/use-auth'; // <-- Import the hook
import { useNavigate } from '@common/utils/hooks/use-navigate'; // <-- Import the navigate hook

import { Tooltip } from 'flowbite-react'

interface PaymentSuccessProps {
  show: boolean;
  onClose: () => void;
  onTopUp: (tier: { credits: number; cost: number }) => void;
  message?: string
}

export function PaymentSuccess({ show, onClose, onTopUp, message='Payment successful. Enjoy your plan!' }: PaymentSuccessProps) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="pt-20">
                  <div className="flex justify-center items-center h-full w-full mb-10">
                      <p className="text-center text-white">{ message }</p>
                  </div>
                  <div className="px-4 pb-10 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-8 mx-auto">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-100  justify-center rounded-md bg-danger p-10 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 mx-auto"
                      onClick={onClose}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                  <div className="pt-16"/>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}