import { ProgressCircle } from "@common/ui/progress/progress-circle";
import { Fragment } from "react";
import {CheckCircleIcon} from '@common/icons/material/CheckCircle';
import { CreditButtonContent } from "@app/landing-page/layout/credit-layout";

interface DownloadPurcharseButtonProps {
    downloading: boolean;
    success: boolean;
    already_downloaded: boolean;
    amount?: number
}
  
export function DownloadPurchase({ downloading, success, already_downloaded,amount }: DownloadPurcharseButtonProps){
    return (
        <button type="submit" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
            <div className='pt-2 align-middle'>
                { (!downloading && !success) && <Fragment>{ already_downloaded ? 'Download Again' : '$24.99' }</Fragment> }
                { downloading && <ProgressCircle
                    aria-label="Loading"
                    fillColor="border-white"
                    isIndeterminate
                    size="md"
                /> }

               { success && <CheckCircleIcon size="md" className="text-white h-40 w-40" /> }
            </div>
        </button>
    );
}

