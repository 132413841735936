import {useMediaQuery, UseMediaQueryOptions} from './use-media-query';

export function useIsTabletMediaQuery(options?: UseMediaQueryOptions) {
  return useMediaQuery('(max-width: 1024px)', options);
}

export function useIsCustomTabletMediaQuery(options?: UseMediaQueryOptions) {
  // Check if the width is between 768px and 1024px (typical tablet range)
  return useMediaQuery("(min-width: 768px) and (max-width: 1024px)", options);
}
