import {NameWithAvatar} from '@app/landing-page/com/datatable/column-templates/name-with-avatar';
import React from 'react';
import {Track} from '@app/landing-page/tracks/track';
import clsx from 'clsx';
import {useTrackTableMeta} from '@app/landing-page/tracks/track-table/use-track-table-meta';
import {getTrackImageSrc} from '@app/landing-page/tracks/track-image/track-image';
import {useIsTrackCued} from '@app/landing-page/tracks/hooks/use-is-track-cued';
import {useIsMobileMediaQuery} from '@common/utils/hooks/is-mobile-media-query';

interface TrackNameColumnProps {
  track: Track;
  forceHide?: boolean
}
export function TrackNameColumn({track,forceHide=false}: TrackNameColumnProps) {
  const isMobile = useIsMobileMediaQuery();
  const {hideTrackImage, queueGroupId} = useTrackTableMeta();
  const isCued = useIsTrackCued(track.id, queueGroupId);

  return (
    <div className=''>
    <NameWithAvatar
      image={!hideTrackImage && !forceHide ? getTrackImageSrc(track) : undefined}
      label={track.name}
      avatarSize={isMobile ? 'lg' : 'md'}
      description={
        false ? track.artists?.map(a => a.name).join(', ') : undefined
      }
      labelClassName={clsx(
        !isMobile && 'text-[16px]',
        isCued && 'text-dangerlighter',
        isMobile && 'text-[16px] leading-6'
      )}
    />
    </div>
  );
}
