import { useSettings } from '@common/core/settings/use-settings';
import { useCuedTrack } from '@app/landing-page/player-controls/use-cued-track';
import { IconButton } from '@common/ui/buttons/icon-button';
import { trackIsLocallyUploaded } from '@app/landing-page/tracks/utils/track-is-locally-uploaded';
import { DownloadIcon } from '@common/icons/material/Download';
import { downloadFileFromUrl } from '@app/landing-page/com/uploads/utils/download-file-from-url';
import { useAuth } from '@app/landing-page/com/auth/use-auth';
import { Tooltip } from '@common/ui/tooltip/tooltip';
import { Trans } from '@common/i18n/trans';


export function DownloadTrackButton() {
  const { player, base_url } = useSettings();
  const track = useCuedTrack();
  const { hasPermission } = useAuth();

  if (
    !player?.enable_download ||
    !track ||
    !trackIsLocallyUploaded(track) ||
    !hasPermission('music.download') ||
    !track.wav_downloads  // Add this line to check if wav_downloads exists
  ) {
    return null;
  }

  return (
    <Tooltip label={<Trans message="1 credit" />}>

      <IconButton
        onClick={() => {
          // Use track.wav_downloads for the URL
          downloadFileFromUrl(`${base_url}/api/v1/download-track/${track.id}`);

        }}
      >
        <DownloadIcon />
      </IconButton>
      
    </Tooltip>
  );
}
