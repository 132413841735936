
import { Disclosure, RadioGroup } from '@headlessui/react'
import { CheckIcon, XMarkIcon, ArrowDownCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

import { Tooltip } from 'flowbite-react'
import { Fragment, SVGProps, useState, useEffect } from 'react';
import { useUser } from '@app/landing-page/com/auth/ui/use-user';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import { Trans } from '@common/i18n/trans';
import { useAuth } from '@common/auth/use-auth';
import { apiClient } from './com/http/query-client';
import ConfirmUpgradePopup from '@common/billing/confirm-upgrade-popup';
import { urlChecker } from '@app/web-player/layout/url-check-if-includes';

const stripe_public_key = "pk_live_51NJGqPESKBqwCn2g7RjF2Mehxxvj3kO1rF6hCKGxufN9tChldGXd5iwHkwjqEajVK3GsVIVM4Qkssuy9c7uD1VJA00eV1Bw4uL"; // live
//const stripe_public_key = "pk_test_51POdgYLgPVZjcxHhaEbDq71YvfqscQY6xwddxO7tnChtfM0sPJYRzej6zDNRa98kc0QUTuD1j5wIZDeyRXKWEZKA00BZZdPnvJ";
//  console.log(stripe_public_key);
const stripePromise = loadStripe(stripe_public_key);

const frequencies = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'annually', label: 'Annually' },
]

let tiers = [
  {
    name: { monthly: 'Standard (monthly)', annually: 'Standard (yearly)' },
    identifier: 'Standard',
    id: 'tier-scale',
    href: { monthly: '#', annually: '#' },
    featured: true,
    description: 'For musicians with experience.',
    price: { monthly: '...', annually: '...' },
    freeTrial: false,
    yearlyCost: { monthly: '', annually: '...' },
    yearlySavings: { monthly: '', annually: '' },
    preDiscountPrice: { monthly: '', annually: '' },
    priceId: { monthly: "", annually: "" },
    mainFeatures: {
      monthly: [
        '2 beats per month',
        'Free professional feedback',
        'Free AI Mastering',
        'Partnership Discounts',
        'Email support',],
      annually: [
        '24 beats per year [instant access]',
        'Free professional feedback',
        'Free AI Mastering',
        'Partnership Discounts',
        'Email support',],
    },
  },
  {
    name: { monthly: 'ADVANCED (monthly)', annually: 'ADVANCED (yearly)' },
    identifier: 'ADVANCED (yearly)',
    id: 'tier-scale',
    href: { monthly: '#', annually: '#' },
    featured: false,
    description: 'For beginners starting out.',
    price: { monthly: '...', annually: '...' },
    freeTrial: true,
    yearlyCost: { monthly: '', annually: '...' },
    yearlySavings: { monthly: '', annually: '(save $40)' },
    preDiscountPrice: { monthly: '', annually: '' },
    priceId: { monthly: "", annually: "" },
    mainFeatures: {
      monthly: [
        '3 beats per month',
        'Free professional feedback',
        'Free AI Mastering',
        'Partnership Discounts',
        'Email support',],
      annually: [
        '36 beats per year [instant access]',
        'Free professional feedback',
        'Free AI Mastering',
        'Partnership Discounts',
        'Email support',],
    },
  },
  //   {
  //     name: 'Advanced',
  //     identifier: 'Advanced',
  //     id: 'tier-growth',
  //     href: { monthly: '#', annually: '#' },
  //     featured: false,
  //     description: 'For professionals who need more.',
  //     price: { monthly: '...', annually: '...' },
  //     freeTrial: false,
  //     yearlyCost: { monthly: '', annually: '...' },
  //     yearlySavings: { monthly: '', annually: '' },
  //     preDiscountPrice: { monthly: '', annually: '' },
  //     priceId: { monthly: "", annually: "" },
  //     mainFeatures: {
  //       monthly: [
  //         '3 beats per month',
  //         'Free professional feedback',
  //         'Free AI Mastering',
  //         'Partnership Discounts',
  //         'Email support',],
  //       annually: [
  //         '36 beats per year [instant access]',
  //         'Free professional feedback',
  //         'Free AI Mastering',
  //         'Partnership Discounts',
  //         'Email support',],
  //     },
  //   },
]

// let subscribedTiers = ["Basic", "Standard", "ADVANCED (yearly)"];
// let unsubscribedTiers = ["Basic", "Standard", "ADVANCED (yearly)"];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

function PricingTooltip() {
  return (
    <div className='p-10'>
      <div className='text-lg pt-8 font-bold text-white text-center mx-auto pb-16'>
        How credits work
      </div>
      <div className='pb-8'>
        <ArrowDownCircleIcon className='text-white h-18 w-auto mx-auto' />
        <div className='text-sm text-white text-center'>
          Get new credits every month to purchase beats
        </div>
      </div>
      <div className='pb-8'>
        <ArrowDownCircleIcon className='text-white h-18 w-auto mx-auto' />
        <div className='text-sm text-white text-center'>
          1 beat = 1 credit
        </div>
      </div>
      <div className='pb-8'>
        <ArrowDownCircleIcon className='text-white h-18 w-auto mx-auto' />
        <div className='text-sm text-white text-center'>
          Unused credits roll over to the next month
        </div>
      </div>
    </div>
  )
}

export function PricingTable() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [prices, setPrices] = useState([]);
  const { data: userData } = useUser('me');

  const { user, isLoggedIn, isSubscribed } = useAuth();
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Popup state

  const currentUrl = window.location.pathname;


  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch('/api/v1/get-prices');
        if (!response.ok) {
          throw new Error('Failed to fetch prices');
        }
        const data = await response.json();
        setPrices(data.data);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };
    fetchPrices();
  }, []);

  //   const filteredTiers = isSubscribed ? tiers.filter(tier => subscribedTiers.includes(tier.name)) : tiers.filter(tier => unsubscribedTiers.includes(tier.name))

  const updatedTiers = tiers.map(tier => {
    const matchedMonthlyPrice = prices.find(price =>
      price.name.toLowerCase() === tier.name.monthly.toLowerCase() && price.interval === 'month'
    );
    const matchedYearlyPrice = prices.find(price =>
      price.name.toLowerCase() === tier.name.annually.toLowerCase() && price.interval === 'year'
    );

    let annuallyPrice = "";
    let monthlyPriceId = "";
    let yearlyPriceId = "";

    if (matchedYearlyPrice) {
      annuallyPrice = "$" + (matchedYearlyPrice?.amount / 12).toFixed(2);
      yearlyPriceId = matchedYearlyPrice?.stripe_id;
      console.log(matchedYearlyPrice)
      console.log(annuallyPrice, yearlyPriceId);
    }

    if (matchedMonthlyPrice) {
      monthlyPriceId = matchedMonthlyPrice?.stripe_id;
    }

    const updatedTier = {
      ...tier,
      price: {
        ...tier.price,
        monthly: matchedMonthlyPrice ? "$" + matchedMonthlyPrice.amount : tier.price.monthly,
        annually: annuallyPrice
      },
      priceId: {
        ...tier.priceId,
        monthly: monthlyPriceId,
        annually: yearlyPriceId

      },
      yearlyCost: {
        ...tier.yearlyCost,
        annually: matchedYearlyPrice ? "$" + matchedYearlyPrice.amount : tier.yearlyCost.annually,
      },
    };

    return updatedTier;
  });

  interface Payload {
    subscriptionId: number;
    newProductId: number;
    newPriceId: number;
    name: string;
  }

  function changePlan({ subscriptionId, ...other }: Payload) {
    return apiClient
      .post(`billing/subscriptions/${subscriptionId}/change-plan`, other)
      .then(r => {
        // console.log(r.data)
        return true;
      })
      .catch(e => {
        // console.log(e);
        return false;
      });
  }
  const handleClose = () => {
    setIsPopupOpen(false);
  };

  // Function to handle confirmation
  const handleConfirm = async () => {
    setIsPopupOpen(false);
    // place your change plan logic here
  };

  const handleSubmit = async (priceId: any) => {
    const subscription: any = user?.subscriptions?.find(obj => obj.active === true && obj.is_expired == 0);
    // console.log(subscription);

    if (isSubscribed && subscription?.price_id !== priceId) {
      setIsPopupOpen(true);
      const getProducts = await fetch('/api/fetch-stripe-products');
      const products = await getProducts.json();
      const newProduct = products?.data.find((product: any) => {
        return product.prices.some((price: any) => price.id === priceId);
      });

      changePlan({ subscriptionId: subscription?.id, newProductId: newProduct?.id, newPriceId: priceId, name: newProduct?.name });
    } else {
      if (!userData?.user) {
        if (priceId) {
          localStorage.setItem('new-user-subscription', JSON.stringify({ 'price': priceId, 'endpoint': currentUrl }));
          window.location.href = "/login?redir=" + currentUrl;
        }
      }
    }

    try {
      const stripe = await stripePromise;
      const token = localStorage.getItem('access_token');
      if (!stripe) {
        console.error('Stripe failed to initialize');
        return;
      }

      const response = await fetch('/api/v1/billing/subscriptions/stripe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ 'stripe_id': priceId }),
      });

      const session = await response.json();

      localStorage.removeItem('new-user-subscription')

      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
        // successUrl: "https://beatsora.com/purchase",
        // cancelUrl: "https://beatsora.com/explore",
        // mode: "subscription"
      });

    } catch (error) {
      console.error('Error occurred:', error);
    }
  }

  const newUserSubscription = JSON.parse(localStorage.getItem('new-user-subscription'));
  if (newUserSubscription !== null) {
    let priceId = newUserSubscription.price;
    const subscription: any = user?.subscriptions?.find(obj => obj.active === true && obj.is_expired == 0);

    if (isSubscribed && subscription) {
      if (priceId === 'price_1PQZHHLgPVZjcxHhGK5ursNN' && subscription.price_id === priceId) {
        priceId = 'price_1PQZIJLgPVZjcxHhjshQBk0Y';
      } else if (priceId === 'price_1PQZKWLgPVZjcxHhoucgcFsY' && subscription.price_id === priceId) {
        priceId = 'price_1PQZIzLgPVZjcxHh4Shc4XkQ';
      }
    }

    if (userData?.user) {
      handleSubmit(priceId);
    }
  }


  function ActionButtonText(tier: any, frequency: any) {
    const subscription: any = user?.subscriptions?.find(obj => obj.active === true && obj.is_expired == 0);

    if (subscription?.price_id === tier.priceId[frequency as keyof typeof tier.priceId] && isLoggedIn) {
      return <Trans message="You're on :plan" values={{ plan: tier.name[frequency as keyof typeof tier.name] }} />;
    }

    // if (tier.freeTrial && subscription?.plan_name !== "STANDARD" && subscription?.plan_name !== "ADVANCED") {
    //     return <Trans message="Start 7-Day Free Trial" />;
    // }

    if (isSubscribed && (tier.name === "ADVANCED (yearly)" || tier.name === "ADVANCED (monthly)")) {
      return <Trans message="Upgrade" />;
    }

    if (isSubscribed && (tier.name === "Standard (yearly)" || tier.name === "Standard (monthly)")) {
      return <Trans message="Downgrade" />;
    }

    if (subscription?.plan_name === "ADVANCED (yearly)" && tier.name === "Standard (yearly)") {
      return <Trans message="Buy this plan" />;
    }

    if (subscription?.plan_name === "ADVANCED (monthly)" && tier.name === "Standard (monthly)") {
      return <Trans message="Buy this plan" />;
    }


    return <Trans message="Buy this plan" />;
  }
  // console.log(updatedTiers);
  const isTrialPage = true;

  // const isTrialPage = urlChecker(['/trial'])
  return (
    <>
      <div className='relative top-350'>
        <ConfirmUpgradePopup isOpen={isPopupOpen} onClose={handleClose} onConfirm={handleConfirm} />
      </div>
      <div>
        <div className="mx-auto max-w-5xl px-6 lg:px-8">
          <div className="relative z-10">
            <p className="text-3xl pt-24 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">
              {isTrialPage ? "Affordable plans for all artists!" : "Subscription Plans"}
            </p>

            <p className="mt-12 pb-24 text-lg text-gray-200 text-center px-8">
              {isTrialPage ? "One license, Release anywhere, Unlimited streams!" : "Make music regularly? Subscribe to receive beats every month, AI mastering, track feedback and perks from our partners!"}

            </p>
            <div className="mt-6 flex justify-center">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-8 text-center text-xs font-semibold leading-5 text-white"
              >
                <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                {frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      classNames(checked ? 'bg-orange-500' : '', 'cursor-pointer rounded-full px-10 py-6')
                    }
                  >
                    <span>{option.label}</span>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className="flex justify-center items-center w-full">
            <div className="relative mx-auto mt-28 grid max-w-2xl grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-2">

              <div
                className="hidden lg:absolute lg:inset-x-px lg:bottom-20 lg:top-20 lg:block lg:rounded-2xl lg:bg-gray-800/80 lg:ring-1 lg:ring-white/30"
                aria-hidden="true"
              />
              {updatedTiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.featured
                      ? 'z-10 bg-gray-800 shadow-xl ring-1 ring-orange-600'
                      : 'bg-gray-900 ring-1 ring-orange-600 lg:bg-transparent lg:pb-14 lg:ring-0',
                    'relative rounded-2xl'
                  )}
                >
                  <div className='pt-24' />
                  <div className="p-20 lg:pt-12 xl:p-10 xl:pt-36 xl:pb-72">
                    <h3
                      id={tier.id}
                      className={classNames(
                        tier.featured ? 'text-white' : 'text-white',
                        'text-md font-bold leading-6'
                      )}
                    >
                      <div className="inline-flex">
                        <div>
                          {tier.name[frequency.value as keyof typeof tier.name]}
                        </div>
                        <Tooltip content={<PricingTooltip />} style="dark">
                          <InformationCircleIcon className='text-white w-24 h-24 pl-6' />
                        </Tooltip>
                      </div>
                    </h3>
                    <div className="flex flex-col lg:flex-col lg:items-stretch">
                      <div className="mt-2 flex items-center pb-10">
                        <p
                          className={classNames(
                            tier.featured ? 'text-white' : 'text-white',
                            'text-4xl font-bold tracking-tight inline-flex'
                          )}
                        >
                          <div>
                            {tier.price[frequency.value as keyof typeof tier.price]}
                          </div>
                          <div className='pl-1 text-2xl'>
                            /month
                          </div>

                        </p>

                      </div>
                      <div className="text-sm leading-5 pb-10">
                        <p className={tier.featured ? 'text-white' : 'text-white'}></p>
                        <p
                          className={tier.featured ? 'text-white' : 'text-white'}
                        >{`${tier.yearlyCost[frequency.value as keyof typeof tier.yearlyCost]} billed ${frequency.value} ${tier.yearlySavings[frequency.value as keyof typeof tier.yearlySavings]}`}</p>
                      </div>
                      <div className='inline-flex pb-20'>
                        {/**
                        <div className="text-lg font-semibold leading-5 line-through pr-10 py-6">
                          <p
                            className={tier.featured ? 'text-white' : 'text-white'}
                          >{`${tier.preDiscountPrice[frequency.value as keyof typeof tier.preDiscountPrice]}`}</p>
                        </div>
                         
                        <div className='text-md font-semibold leading-5 text-white bg-orange-600 px-6 py-6  '>
                          BETA Discount
                        </div>
                        */}
                      </div>
                      <button onClick={(e) => handleSubmit(tier.priceId[frequency.value as keyof typeof tier.priceId])} className={classNames(
                        tier.featured
                          ? 'bg-orange-600 shadow-sm hover:bg-orange-500 focus-visible:outline-orange-600'
                          : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                        'rounded-md py-10 px-10 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                      )}>
                        {/* {tier.freeTrial ? "Start free trial" : "Buy this plan"} */}
                        {ActionButtonText(tier, frequency.value)}
                      </button>
                      {/* <a
                        href={tier.href[frequency.value as keyof typeof tier.href]}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.featured
                            ? 'bg-orange-600 shadow-sm hover:bg-orange-500 focus-visible:outline-orange-600'
                            : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                          'rounded-md py-10 px-10 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                        )}
                      >
                        {tier.priceId[frequency.value as keyof typeof tier.priceId]}
                        Buy this plan
                      </a> */}
                    </div>
                    <div className="mt-8 flow-root sm:mt-20">
                      <ul
                        role="list"
                        className={classNames(
                          tier.featured
                            ? 'divide-white/10 border-white/10 text-white'
                            : 'divide-white/10 border-white/10 text-white',
                          '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0'
                        )}
                      >
                        {tier.mainFeatures[frequency.value as keyof typeof tier.mainFeatures].map((mainFeature) => (
                          <li key={mainFeature} className="flex gap-x-16 py-2 pb-72 w-280">
                            <CheckIcon
                              className={classNames(
                                tier.featured ? 'text-orange-600' : 'text-gray-500',
                                'h-18 w-15 flex-none'
                              )}
                              aria-hidden="true"
                            />
                            {mainFeature}
                          </li>
                        ))}
                        <div className="pt-24 lg:pt-64" />
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/*
            <div className='mx-auto max-w-3xl'>
              <div className='text-xl pt-48 font-semibold text-white text-center mx-auto pb-16'>
                How credits work
              </div>
              <div className='pb-8'>
                <ArrowDownCircleIcon className='text-white h-26 w-auto mx-auto' />
                <div className='text-lg text-white text-center'>
                  Get new credits every month to purchase beats
                </div>
              </div>
              <div className='pb-8'>
                <ArrowDownCircleIcon className='text-white h-26 w-auto mx-auto' />
                <div className='text-lg text-white text-center'>
                  1 beat = 1 credit
                </div>
              </div>
              <div className='pb-8'>
                <ArrowDownCircleIcon className='text-white h-26 w-auto mx-auto' />
                <div className='text-lg text-white text-center'>
                  Unused credits roll over to the next month
                </div>
              </div>
            </div>
              */}
        </div>
        <div className='pt-40' />
        <div className="mx-auto max-w-4xl text-center pb-16 mt-2 text-xl font-semibold tracking-tight text-white">
          Are you a label or organization looking for a custom package? Reach out so we can discuss your requirements:
        </div>
        <a href="https://forms.monday.com/forms/0fe644bf37eb3ac0633c178c1b1e05b7?r=euc1" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter  text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
          <div className='pt-8'>
            Contact Us
          </div>
        </a>
        <div className='pt-64' />
        {/*<hr className="h-[2px] mx-auto max-w-7xl border-t-0 bg-transparent bg-gradient-to-r from-transparent via-gray-200 to-transparent opacity-75 dark:opacity-100" />*/}
      </div>
    </>
  )
}