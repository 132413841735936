import React from 'react';
import { Track } from '@app/landing-page/tracks/track';

interface TrackBpmColumnProps {
    bpm: string;
}

export function TrackBpmColumn({ bpm }: TrackBpmColumnProps) {
    return (
        <div>
            {bpm}
        </div>
    );
}
