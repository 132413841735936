import {HeaderCell} from '@common/ui/tables/header-cell';
import React, {useContext} from 'react';
import {TableContext} from '@common/ui/tables/table-context';
import {useIsMobileMediaQuery} from '@common/utils/hooks/is-mobile-media-query';

export function TableHeaderRow() {
  const {columns} = useContext(TableContext);
  const isMobile = useIsMobileMediaQuery();
  return (
    <div
      role="row"
      aria-rowindex={1}
      tabIndex={-1}
      className={"flex  px-16 "+(!isMobile ? 'gap-x-16' : '')}
    >
      {columns.map((column, columnIndex) => (
        <HeaderCell index={columnIndex} key={column.key} />
      ))}
    </div>
  );
}
