import Image from 'next/image'

export function ImageSection( {img} : {img:any} ) {
    return (
        <div className=" gap-4 sm:gap-6 lg:gap-12">
                <img
                    className="pt-8 w-auto h-full max-h-[250px] mx-auto"
                    src={img}
                    alt="Beatsora"

                />

        </div>
      )
}



export function TextSectionLeft( {hdr, dsc} : {hdr:any, dsc:any} ) {
    return (
        <div className='text-white'>
            
              <dl className="mt-8 ">
                
                  <div key={hdr} className="border-t border-gray-400 m-auto pt-12 px-12">
                    <dt className={"text-xl sm:text-2xl lg:text-3xl font-semibold text-white text-center m-auto lg:text-left"}>{hdr}</dt>
                    <dd className={"mt-8 text-md sm:text-lg lg:text-xl text-gray-200 text-center m-auto lg:text-left"}>{dsc}</dd>
                  </div>
                
              </dl>
              
            </div>
      )
}

export function TextSectionRight( {hdr, dsc} : {hdr:any, dsc:any} ) {
    return (
        <div className='text-white'>
            
              <dl className="mt-8 ">
                
                  <div key={hdr} className="border-t border-gray-400 m-auto pt-12 px-12">
                    <dt className={"text-xl sm:text-2xl lg:text-3xl font-semibold text-white text-center m-auto lg:text-right"}>{hdr}</dt>
                    <dd className={"mt-8 text-md sm:text-lg lg:text-xl text-gray-200 text-center m-auto lg:text-right"}>{dsc}</dd>
                  </div>
                
              </dl>
              
            </div>
      )
}

export function TileTextLeft( {hdr, dsc, img, link} : {hdr:any, dsc:any, img:any, link:any} ) {
    return (
        <>
        <TextSectionLeft
                hdr = {hdr}
                dsc = {dsc}
            />
            <a href={link}>
                <ImageSection
                    img = {img}
                />
            </a>
        </>
      )
}

export function TileTextRight( {hdr, dsc, img, link} : {hdr:any, dsc:any, img:any, link:any} ) {
    return (
        <>
        <div className='hidden lg:block'>

        <a href={link}>
            <ImageSection
                img = {img}
            />
        </a>    
        </div>
        <TextSectionRight
            hdr = {hdr}
            dsc = {dsc}
        />
        <div className='block lg:hidden'>

        <a href={link}>
            <ImageSection
                img = {img}
            />
        </a>
        </div>
        </>
      )
}
    