import { ArtistLinks } from '@app/landing-page/artists/artist-links';
import { Trans } from '@common/i18n/trans';
import { Track } from '@app/landing-page/tracks/track';
import {
  ContextDialogLayout,
  ContextMenuButton,
  ContextMenuLayoutProps,
} from '@app/landing-page/context-dialog/context-dialog-layout';
import { PlaylistPanelButton } from '@app/landing-page/context-dialog/playlist-panel';
import { CopyLinkMenuButton } from '@app/landing-page/context-dialog/copy-link-menu-button';
import { getTrackLink, TrackLink } from '@app/landing-page/tracks/track-link';
import { TrackImage } from '@app/landing-page/tracks/track-image/track-image';
import { useTrackPermissions } from '@app/landing-page/tracks/hooks/use-track-permissions';
import { AddToQueueButton } from '@app/landing-page/context-dialog/add-to-queue-menu-button';
import React, { Fragment, ReactNode, useCallback } from 'react';
import { ToggleInLibraryMenuButton } from '@app/landing-page/context-dialog/toggle-in-library-menu-button';
import { ToggleRepostMenuButton } from '@app/landing-page/context-dialog/toggle-repost-menu-button';
import { getRadioLink } from '@app/landing-page/radio/get-radio-link';
import { useShouldShowRadioButton } from '@app/landing-page/tracks/context-dialog/use-should-show-radio-button';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { openDialog } from '@common/ui/overlays/store/dialog-store';
import { ConfirmationDialog } from '@common/ui/overlays/dialog/confirmation-dialog';
import { useDeleteTracks } from '@app/landing-page/tracks/requests/use-delete-tracks';
import { useIsMobileMediaQuery } from '@common/utils/hooks/is-mobile-media-query';
import { getArtistLink } from '@app/landing-page/artists/artist-link';
import { getAlbumLink } from '@app/landing-page/albums/album-link';
import { ShareMediaButton } from '@app/landing-page/context-dialog/share-media-button';
import { useSettings } from '@common/core/settings/use-settings';
import { LyricsDialog } from '@app/landing-page/tracks/lyrics/lyrics-dialog';
import { DownloadTrackMenuButton } from '@app/landing-page/context-dialog/download-track-menu-button';
import DownloadLicenseTrackMenuButton from '@app/landing-page/context-dialog/download-license-menu-button';

export interface TrackContextDialogProps {
  tracks: Track[];
  children?: (tracks: Track[]) => ReactNode;
  showAddToQueueButton?: boolean;
}


export function TrackContextDialog({
  children,
  tracks,
  showAddToQueueButton = true,
}: TrackContextDialogProps) {
  const isMobile = useIsMobileMediaQuery();
  const firstTrack = tracks[0];
  const { canEdit, canDelete } = useTrackPermissions(tracks);
  const shouldShowRadio = useShouldShowRadioButton();
  const { player } = useSettings();
  const { close } = useDialogContext();

  const loadTracks = useCallback(() => {
    return Promise.resolve(tracks);
  }, [tracks]);

  const headerProps: Partial<ContextMenuLayoutProps> =
    tracks.length === 1
      ? {
        image: <TrackImage track={firstTrack} />,
        title: <TrackLink track={firstTrack} />,
        description: <ArtistLinks artists={firstTrack.artists} />,
      }
      : {};

      // console.log("Ukaguzi ya matokeo",firstTrack.albums);

  return (
    <ContextDialogLayout {...headerProps} loadTracks={loadTracks}>
      {showAddToQueueButton && (
        <AddToQueueButton item={tracks} loadTracks={loadTracks} />
      )}
      <ToggleInLibraryMenuButton items={tracks} />
      {children?.(tracks)}
      <PlaylistPanelButton />
      {tracks.length === 1 && (
        <Fragment>
          {shouldShowRadio && (
            <ContextMenuButton type="link" to={getRadioLink(firstTrack)}>
              <Trans message="Go to song radio" />
            </ContextMenuButton>
          )}
          {isMobile && (
            <Fragment>
              {/* {firstTrack.artists?.[0] && (
                <ContextMenuButton
                  type="link"
                  to={getArtistLink(firstTrack.artists[0])}
                >
                  <Trans message="Go to artist" />
                </ContextMenuButton>
              )} */}
              {firstTrack?.albums && firstTrack?.albums?.length > 0 && (
                <ContextMenuButton
                  type="link"
                  to={getAlbumLink(firstTrack.albums[0])}
                >
                  <Trans message="Go to album" />
                </ContextMenuButton>
              )}
              <ContextMenuButton type="link" to={getTrackLink(firstTrack)}>
                <Trans message="Go to track" />
              </ContextMenuButton>
            </Fragment>
          )}
          {!player?.hide_lyrics && tracks.length === 1 && (
            <ContextMenuButton
              onClick={() => {
                close();
                openDialog(LyricsDialog, { track: firstTrack });
              }}
            >
              <Trans message="View lyrics" />
            </ContextMenuButton>
          )}
          {!isMobile && (
            <CopyLinkMenuButton
              link={getTrackLink(firstTrack, { absolute: true })}
            >
              <Trans message="Copy song link" />
            </CopyLinkMenuButton>
          )}
          {tracks.length === 1 && <ShareMediaButton item={firstTrack} />}
          {tracks.length === 1 ? (
            <ToggleRepostMenuButton item={tracks[0]} />
          ) : null}
          {tracks.length === 1 && canEdit && (
            <ContextMenuButton
              type="link"
              to={`/backstage/tracks/${firstTrack.id}/insights`}
            >
              <Trans message="Insights" />
            </ContextMenuButton>
          )}
          {tracks.length === 1 && canEdit && (
            <ContextMenuButton
              type="link"
              to={`/backstage/tracks/${firstTrack.id}/edit`}
            >
              <Trans message="Edit" />
            </ContextMenuButton>
          )}
          {tracks.length === 1 && (
            <>
            <ContextMenuButton>
              <DownloadTrackMenuButton track={firstTrack} />
              {/* <DownloadLicenseTrackMenuButton track={firstTrack} /> */}
            </ContextMenuButton>
              
            </>
          )}
        </Fragment>
      )}
      {canDelete && !isMobile && <DeleteButton tracks={tracks} />}
    </ContextDialogLayout>
  );
}

function DeleteButton({ tracks }: TrackContextDialogProps) {
  const { close: closeMenu } = useDialogContext();
  const deleteTracks = useDeleteTracks();
  const { canDelete } = useTrackPermissions(tracks);

  if (!canDelete) {
    return null;
  }

  return (
    <ContextMenuButton
      disabled={deleteTracks.isLoading}
      onClick={() => {
        closeMenu();
        openDialog(ConfirmationDialog, {
          isDanger: true,
          title: <Trans message="Delete tracks" />,
          body: (
            <Trans message="Are you sure you want to delete selected tracks?" />
          ),
          confirm: <Trans message="Delete" />,
          onConfirm: () => {
            deleteTracks.mutate({ trackIds: tracks.map(t => t.id) });
          },
        });
      }}
    >
      <Trans message="Delete" />
    </ContextMenuButton>
  );
}
