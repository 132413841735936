import React from 'react';
import { Track } from '@app/landing-page/tracks/track';

interface TrackKeyColumnProps {
    trackKey: string;
}

export function TrackKeyColumn({ trackKey }: TrackKeyColumnProps) {
    return (
        <div>
            {trackKey}
        </div>
    );
}